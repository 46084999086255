import React from 'react'
import RepairIndex from '../Repair/RepairIndex'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'


const Services_ = () => {
  return (
    <>
    <Navbar/>    
    <RepairIndex/>
    <Footer/>
  </>
  )
}

export default Services_