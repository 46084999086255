import React from 'react';
import './Help.css'

function Help() {
  return (
      <>
          <section className='help-showcase'>
                <div className='help container'>
                <div className='help-content' data-aos="zoom-out">
                <h2>repair services</h2>
                    <h1>How can we help you ?</h1>
                </div>
                    
                </div>
          </section>
      </>
  )
}

export default Help;
