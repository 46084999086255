import React from 'react'
import Contact from '../Contact/Contact'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Help from '../Help/Help'

const Contact_ = () => {
  return (
    <>
    <Navbar/>
    <Help/>
   <Contact/>
    <Footer/>
</>
  )
}

export default Contact_