import React from 'react';

const Pdata = [
    {
        popImage : 'icons/popular-01.jpg',
        popHeading : 'Door Painting',
        popText : 'Lorem ipsum dolor sit amet consectetur dolor sit a adipisicing elit. Praesentium, perferendis?'
    },
    {
        popImage : 'icons/popular-02.jpg',
        popHeading : 'Garbage Disposal',
        popText : 'Lorem ipsum dolor sit amet consectetur adipisicing  dolor sit aelit. Praesentium, perferendis?'
    },
    {
        popImage : 'icons/popular-03.jpg',
        popHeading : 'Plumbing Service',
        popText : 'Lorem ipsum dolor sit amet consectetur adipisicing dolor sit a elit. Praesentium, perferendis?'
    }
]

export default Pdata;
